import { useState, useEffect } from "react";
import { countCharacters } from "@root/utils/countCharacters";
import { EMPLOYMENT_TYPE } from "@root/utils/constants";
import { CheckboxState, ErrorItem, TextAreaState } from "./type";
import {
  SALARYDATA_TEXTFIELD_MIN_LENGTH,
  SALARYDATA_TEXTFIELD_MAX_LENGTH,
  SALARYDATA_TEXTFIELD_PART_TIME_MIN_LENGTH,
  SALARYDATA_TEXTFIELD_PART_TIME_MAX_LENGTH,
  BONUS_TEXTFIELD_PART_PAYMENT_COUNT_MIN_LENGTH,
  BONUS_TEXTFIELD_PART_PAYMENT_COUNT_MAX_LENGTH,
} from "./constants";

type ReturnType = {
  isValid: boolean;
  errorTexts: ErrorItem;
  updateClickStatus: () => void;
};

// フロント側バリデーション
export const useValidationText = (
  // TreatmentAndCulture
  checkboxValues: CheckboxState,
  // WorkplaceReviewFeature
  radioValues: Record<string, number | null>,
  // WorkplaceReviewThought
  textAreaState: TextAreaState,
  railsValidationTexts: ErrorItem[],
  // BasicAndEmployment
  clientIdValue?: number,
  businessTypeValue?: number,
  occupationValue?: number,
  employmentValue?: number,
  continuedServiceYearsValue?: number,
  // SalaryData
  monthlyIncomeValue?: number | null,
  minimumGuaranteedIncomeValue?: number | null,
  grossMonthlyIncomeValue?: number | null,
  hourlyWageValue?: number | null,
  paymentYearValue?: number | null,
  paymentCountValue?: number | null,
  totalAmountValue?: number | null,
  noGuaranteedIncomeFlag?: boolean,
  noBonusFlag?: boolean,
): ReturnType => {
  // 送信ボタンを一度でも押したことがあるか
  const [isClicked, setIsClicked] = useState<boolean>(false);

  // エラー文言全体
  const [errorTexts, setErrorTexts] = useState<ErrorItem>({});

  // 送信ボタン押下時に一括errorTextsに反映できるよう、各項目ごとのエラー文言を個別で管理する
  // BasicAndEmployment
  // 基本情報(投稿先)
  const [clientErrorText, setClientErrorText] = useState<string | null>(null);
  // 就業情報(就業状況)
  const [employmentStatusErrorText, setEmploymentStatusErrorText] = useState<string | null>(null);
  // 就業状況(勤続年数)
  const [continuedServiceYearsErrorText, setContinuedServiceYearsErrorText] = useState<
    string | null
  >(null);
  // 就業情報(職種)
  const [businessTypesErrorText, setBusinessTypesErrorText] = useState<string | null>(null);
  // 就業情報(役職)
  const [occupationErrorText, setOccupationErrorText] = useState<string | null>(null);
  // 就業情報(雇用形態)
  const [employmentErrorText, setEmploymentErrorText] = useState<string | null>(null);

  // TreatmentAndCulture
  // 待遇面の口コミ
  const [treatmentsErrorText, setTreatmentsErrorText] = useState<string | null>(null);
  // 社風面の口コミ
  const [culturesErrorText, setCulturesErrorText] = useState<string | null>(null);

  // WorkplaceReviewFeature
  // 職場の口コミ(特徴)
  const [atmosphereErrorText, setAtmosphereErrorText] = useState<string | null>(null);
  const [salaryStandardErrorText, setSalaryStandardErrorText] = useState<string | null>(null);
  const [debutErrorText, setDebutErrorText] = useState<string | null>(null);
  const [educationErrorText, setEducationErrorText] = useState<string | null>(null);
  const [workTimeErrorText, setWorkTimeErrorText] = useState<string | null>(null);
  const [salaryChangeErrorText, setSalaryChangeErrorText] = useState<string | null>(null);

  // SalaryData
  // 給与データ
  const [monthlyIncomeErrorText, setMonthlyIncomeErrorText] = useState<string | null>(null);
  const [minimumGuaranteedIncomeErrorText, setMinimumGuaranteedIncomeErrorText] = useState<
    string | null
  >(null);
  const [grossMonthlyIncomeErrorText, setGrossMonthlyIncomeErrorText] = useState<string | null>(
    null,
  );
  const [hourlyWageErrorText, setHourlyWageErrorText] = useState<string | null>(null);
  const [paymentYearErrorText, setPaymentYearErrorText] = useState<string | null>(null);
  const [paymentCountErrorText, setPaymentCountErrorText] = useState<string | null>(null);
  const [totalAmountErrorText, setTotalAmountErrorText] = useState<string | null>(null);

  // WorkplaceReviewThought
  // 職場の口コミ(あなたの考え/想い)
  const [workplaceMatchingErrorText, setWorkplaceMatchingErrorText] = useState<string | null>(null);
  const [interestRewardErrorText, setInterestRewardErrorText] = useState<string | null>(null);
  const [challengeErrorText, setChallengeErrorText] = useState<string | null>(null);
  const [expectationErrorText, setExpectationErrorText] = useState<string | null>(null);

  // バリデーション通過判定
  const [isValid, setIsValid] = useState(false);

  const CHECKBOX_MIN_LENGTH = 1;
  const CHECKBOX_MAX_LENGTH = 5;
  // 共通で使用するバリデーション文言
  const PLEASE_CHOOSE_VALIDATION_TEXT = "選択してください。";
  const PLEASE_FILL_VALIDATION_TEXT = "入力してください。";
  const MIN_LENGTH_VALIDATION_TEXT = `${CHECKBOX_MIN_LENGTH}つ以上選択してください。`;
  const MAX_LENGTH_VALIDATION_TEXT = `${CHECKBOX_MAX_LENGTH}つ以下にしてください。`;
  const TEXTAREA_CHARACTER_LENGTH_VALIDATION_TEXT = "100~1000字で入力してください。";
  const TEXTFIELD_ONLY_NUMBER_VALIDATION_TEXT = "半角整数で入力してください。";
  const TEXTFIELD_DIGITS_VALIDATION_SHORT_TEXT = "3桁以上5桁以内で入力してください。";
  const TEXTFIELD_DIGITS_VALIDATION_LONG_TEXT = "5桁以上7桁以内で入力してください。";
  const TEXTFIELD_INVALID_VALUE_VALIDATION_TEXT = "値が不正です。";
  const TEXTFIELD_GROSS_MONTHLY_INCOME_MUST_BE_HIGHER_THAN_MONTHLY_INCOME_VALIDATION_TEXT =
    "総支給(月)は基本給(月)より高く設定してください。";
  const TEXTFIELD_GROSS_MONTHLY_INCOME_MUST_BE_HIGHER_THAN_MINIMUM_GUARANTEED_INCOME_VALIDATION_TEXT =
    "総支給(月)は最低保証給(月)より高く設定してください。";

  const updateClickStatus = () => {
    setIsClicked(true);
  };

  // Rails側バリデーションエラー文言を反映する
  useEffect(() => {
    const errorsResponse: ErrorItem = {};
    railsValidationTexts?.forEach((errorMessage: ErrorItem) => {
      const key = Object.keys(errorMessage)[0];
      const message = errorMessage[key];
      errorsResponse[key] = message;
    });
    setErrorTexts(errorsResponse);
  }, [railsValidationTexts]);

  // BasicAndEmployment 基本情報(投稿先)
  useEffect(() => {
    if (!clientIdValue) {
      setClientErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setClientErrorText(null);
  }, [clientIdValue]);

  // BasicAndEmployment 就業情報(就業状況)
  useEffect(() => {
    if (!radioValues?.employmentStatus) {
      setEmploymentStatusErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setEmploymentStatusErrorText(null);
  }, [radioValues?.employmentStatus]);

  // BasicAndEmployment 就業情報(勤続年数)
  useEffect(() => {
    if (!continuedServiceYearsValue) {
      setContinuedServiceYearsErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setContinuedServiceYearsErrorText(null);
  }, [continuedServiceYearsValue]);

  // BasicAndEmployment 就業情報(職種)
  useEffect(() => {
    if (!businessTypeValue) {
      setBusinessTypesErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setBusinessTypesErrorText(null);
  }, [businessTypeValue]);

  // BasicAndEmployment 就業情報(役職)
  useEffect(() => {
    if (!occupationValue) {
      setOccupationErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setOccupationErrorText(null);
  }, [occupationValue]);

  // BasicAndEmployment 就業情報(雇用形態)
  useEffect(() => {
    if (!employmentValue) {
      setEmploymentErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setEmploymentErrorText(null);
  }, [employmentValue]);

  // TreatmentAndCulture 待遇面の口コミ
  useEffect(() => {
    if (!checkboxValues?.treatments || checkboxValues?.treatments.length === 0) {
      setTreatmentsErrorText(MIN_LENGTH_VALIDATION_TEXT);
      return;
    }
    if (checkboxValues?.treatments.length > CHECKBOX_MAX_LENGTH) {
      setTreatmentsErrorText(MAX_LENGTH_VALIDATION_TEXT);
      return;
    }
    setTreatmentsErrorText(null);
  }, [MAX_LENGTH_VALIDATION_TEXT, MIN_LENGTH_VALIDATION_TEXT, checkboxValues?.treatments]);

  // TreatmentAndCulture 社風面の口コミ
  useEffect(() => {
    if (!checkboxValues?.cultures || checkboxValues?.cultures.length === 0) {
      setCulturesErrorText(MIN_LENGTH_VALIDATION_TEXT);
      return;
    }
    if (checkboxValues?.cultures.length > CHECKBOX_MAX_LENGTH) {
      setCulturesErrorText(MAX_LENGTH_VALIDATION_TEXT);
      return;
    }
    setCulturesErrorText(null);
  }, [MAX_LENGTH_VALIDATION_TEXT, MIN_LENGTH_VALIDATION_TEXT, checkboxValues?.cultures]);

  // WorkplaceReviewFeature 雰囲気
  useEffect(() => {
    if (!radioValues?.atmosphere) {
      setAtmosphereErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setAtmosphereErrorText(null);
  }, [radioValues?.atmosphere]);

  // WorkplaceReviewFeature 給与体系
  useEffect(() => {
    if (!radioValues?.salaryStandard) {
      setSalaryStandardErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setSalaryStandardErrorText(null);
  }, [radioValues?.salaryStandard]);

  // WorkplaceReviewFeature デビュー
  useEffect(() => {
    if (!radioValues?.debut) {
      setDebutErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setDebutErrorText(null);
  }, [radioValues?.debut]);

  // WorkplaceReviewFeature 教育方針
  useEffect(() => {
    if (!radioValues?.education) {
      setEducationErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setEducationErrorText(null);
  }, [radioValues?.education]);

  // WorkplaceReviewFeature 勤務時間
  useEffect(() => {
    if (!radioValues?.workTime) {
      setWorkTimeErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setWorkTimeErrorText(null);
  }, [radioValues?.workTime]);

  // WorkplaceReviewFeature 給与の変化
  useEffect(() => {
    if (!radioValues?.salaryChange) {
      setSalaryChangeErrorText(PLEASE_CHOOSE_VALIDATION_TEXT);
      return;
    }
    setSalaryChangeErrorText(null);
  }, [radioValues?.salaryChange]);

  // SalaryData 基本給(月)
  useEffect(() => {
    if (
      employmentValue !== EMPLOYMENT_TYPE.FULL_TIME_ID &&
      employmentValue !== EMPLOYMENT_TYPE.CONTRACT_ID
    ) {
      setMonthlyIncomeErrorText(null);
      return;
    }
    if (!monthlyIncomeValue) {
      setMonthlyIncomeErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }
    if (!Number.isInteger(monthlyIncomeValue)) {
      setMonthlyIncomeErrorText(TEXTFIELD_ONLY_NUMBER_VALIDATION_TEXT);
      return;
    }
    const valueLength = monthlyIncomeValue.toString().length;
    if (
      valueLength < SALARYDATA_TEXTFIELD_MIN_LENGTH ||
      valueLength > SALARYDATA_TEXTFIELD_MAX_LENGTH
    ) {
      setMonthlyIncomeErrorText(TEXTFIELD_DIGITS_VALIDATION_LONG_TEXT);
      return;
    }
    setMonthlyIncomeErrorText(null);
  }, [employmentValue, monthlyIncomeValue]);

  // SalaryData 最低保証給(月)
  useEffect(() => {
    if (employmentValue !== EMPLOYMENT_TYPE.FREELANCE_ID || noGuaranteedIncomeFlag) {
      setMinimumGuaranteedIncomeErrorText(null);
      return;
    }
    if (!minimumGuaranteedIncomeValue) {
      setMinimumGuaranteedIncomeErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }
    if (!Number.isInteger(minimumGuaranteedIncomeValue)) {
      setMinimumGuaranteedIncomeErrorText(TEXTFIELD_ONLY_NUMBER_VALIDATION_TEXT);
      return;
    }
    const valueLength = minimumGuaranteedIncomeValue.toString().length;
    if (
      valueLength < SALARYDATA_TEXTFIELD_MIN_LENGTH ||
      valueLength > SALARYDATA_TEXTFIELD_MAX_LENGTH
    ) {
      setMinimumGuaranteedIncomeErrorText(TEXTFIELD_DIGITS_VALIDATION_LONG_TEXT);
      return;
    }
    setMinimumGuaranteedIncomeErrorText(null);
  }, [noGuaranteedIncomeFlag, employmentValue, minimumGuaranteedIncomeValue]);

  // SalaryData 総支給(月)
  useEffect(() => {
    if (
      employmentValue !== EMPLOYMENT_TYPE.FULL_TIME_ID &&
      employmentValue !== EMPLOYMENT_TYPE.CONTRACT_ID &&
      employmentValue !== EMPLOYMENT_TYPE.FREELANCE_ID
    ) {
      setGrossMonthlyIncomeErrorText(null);
      return;
    }
    if (!grossMonthlyIncomeValue) {
      setGrossMonthlyIncomeErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }
    if (!Number.isInteger(grossMonthlyIncomeValue)) {
      setGrossMonthlyIncomeErrorText(TEXTFIELD_ONLY_NUMBER_VALIDATION_TEXT);
      return;
    }
    const valueLength = grossMonthlyIncomeValue.toString().length;
    if (
      valueLength < SALARYDATA_TEXTFIELD_MIN_LENGTH ||
      valueLength > SALARYDATA_TEXTFIELD_MAX_LENGTH
    ) {
      setGrossMonthlyIncomeErrorText(TEXTFIELD_DIGITS_VALIDATION_LONG_TEXT);
      return;
    }
    if (
      (employmentValue === EMPLOYMENT_TYPE.FULL_TIME_ID ||
        employmentValue === EMPLOYMENT_TYPE.CONTRACT_ID) &&
      typeof monthlyIncomeValue === "number" &&
      grossMonthlyIncomeValue < monthlyIncomeValue
    ) {
      setGrossMonthlyIncomeErrorText(
        TEXTFIELD_GROSS_MONTHLY_INCOME_MUST_BE_HIGHER_THAN_MONTHLY_INCOME_VALIDATION_TEXT,
      );
      return;
    }
    if (
      employmentValue === EMPLOYMENT_TYPE.FREELANCE_ID &&
      typeof minimumGuaranteedIncomeValue === "number" &&
      grossMonthlyIncomeValue < minimumGuaranteedIncomeValue
    ) {
      if (noGuaranteedIncomeFlag) {
        setGrossMonthlyIncomeErrorText(null);
        return;
      }
      setGrossMonthlyIncomeErrorText(
        TEXTFIELD_GROSS_MONTHLY_INCOME_MUST_BE_HIGHER_THAN_MINIMUM_GUARANTEED_INCOME_VALIDATION_TEXT,
      );
      return;
    }
    setGrossMonthlyIncomeErrorText(null);
  }, [
    employmentValue,
    grossMonthlyIncomeValue,
    monthlyIncomeValue,
    minimumGuaranteedIncomeValue,
    noGuaranteedIncomeFlag,
  ]);

  // SalaryData 時給
  useEffect(() => {
    if (employmentValue !== EMPLOYMENT_TYPE.PART_TIME_ID) {
      setHourlyWageErrorText(null);
      return;
    }
    if (!hourlyWageValue) {
      setHourlyWageErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }
    if (!Number.isInteger(hourlyWageValue)) {
      setHourlyWageErrorText(TEXTFIELD_ONLY_NUMBER_VALIDATION_TEXT);
      return;
    }
    const valueLength = hourlyWageValue.toString().length;
    if (
      valueLength < SALARYDATA_TEXTFIELD_PART_TIME_MIN_LENGTH ||
      valueLength > SALARYDATA_TEXTFIELD_PART_TIME_MAX_LENGTH
    ) {
      setHourlyWageErrorText(TEXTFIELD_DIGITS_VALIDATION_SHORT_TEXT);
      return;
    }
    setHourlyWageErrorText(null);
  }, [employmentValue, hourlyWageValue]);

  // SalaryData 支給年度
  useEffect(() => {
    if (employmentValue === EMPLOYMENT_TYPE.OTHER_ID || noBonusFlag) {
      setPaymentYearErrorText(null);
      return;
    }
    if (!paymentYearValue) {
      setPaymentYearErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }
    setPaymentYearErrorText(null);
  }, [noBonusFlag, employmentValue, paymentYearValue]);

  // SalaryData 支給回数(年)
  useEffect(() => {
    if (employmentValue === EMPLOYMENT_TYPE.OTHER_ID || noBonusFlag) {
      setPaymentCountErrorText(null);
      return;
    }
    if (!paymentCountValue) {
      setPaymentCountErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }
    if (
      paymentCountValue < BONUS_TEXTFIELD_PART_PAYMENT_COUNT_MIN_LENGTH ||
      paymentCountValue > BONUS_TEXTFIELD_PART_PAYMENT_COUNT_MAX_LENGTH
    ) {
      setPaymentCountErrorText(TEXTFIELD_INVALID_VALUE_VALIDATION_TEXT);
      return;
    }
    setPaymentCountErrorText(null);
  }, [noBonusFlag, employmentValue, paymentCountValue]);

  // SalaryData 支給総額(年)
  useEffect(() => {
    if (employmentValue === EMPLOYMENT_TYPE.OTHER_ID || noBonusFlag) {
      setTotalAmountErrorText(null);
      return;
    }
    if (!totalAmountValue) {
      setTotalAmountErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }
    if (!Number.isInteger(totalAmountValue)) {
      setTotalAmountErrorText(TEXTFIELD_ONLY_NUMBER_VALIDATION_TEXT);
      return;
    }
    const valueLength = totalAmountValue.toString().length;
    if (
      valueLength < SALARYDATA_TEXTFIELD_MIN_LENGTH ||
      valueLength > SALARYDATA_TEXTFIELD_MAX_LENGTH
    ) {
      setTotalAmountErrorText(TEXTFIELD_DIGITS_VALIDATION_LONG_TEXT);
      return;
    }
    setTotalAmountErrorText(null);
  }, [noBonusFlag, employmentValue, totalAmountValue]);

  // WorkplaceReviewThought 職場に合う人
  useEffect(() => {
    if (!textAreaState?.workplaceMatching) {
      setWorkplaceMatchingErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }

    // 文字数のバリデーション
    const count = countCharacters(textAreaState?.workplaceMatching);
    if (count < 100 || count > 1000) {
      setWorkplaceMatchingErrorText(TEXTAREA_CHARACTER_LENGTH_VALIDATION_TEXT);
      return;
    }

    setWorkplaceMatchingErrorText(null);
  }, [textAreaState?.workplaceMatching, setWorkplaceMatchingErrorText]);

  // WorkplaceReviewThought 面白み・やりがい
  useEffect(() => {
    if (!textAreaState?.interestReward) {
      setInterestRewardErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }

    // 文字数のバリデーション
    const count = countCharacters(textAreaState?.interestReward);
    if (count < 100 || count > 1000) {
      setInterestRewardErrorText(TEXTAREA_CHARACTER_LENGTH_VALIDATION_TEXT);
      return;
    }

    setInterestRewardErrorText(null);
  }, [textAreaState?.interestReward]);

  // WorkplaceReviewThought 挑戦できること
  useEffect(() => {
    if (!textAreaState?.challenge) {
      setChallengeErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }

    // 文字数のバリデーション
    const count = countCharacters(textAreaState?.challenge);
    if (count < 100 || count > 1000) {
      setChallengeErrorText(TEXTAREA_CHARACTER_LENGTH_VALIDATION_TEXT);
      return;
    }

    setChallengeErrorText(null);
  }, [textAreaState?.challenge]);

  // WorkplaceReviewThought 期待すること
  useEffect(() => {
    if (!textAreaState?.expectation) {
      setExpectationErrorText(PLEASE_FILL_VALIDATION_TEXT);
      return;
    }

    // 文字数のバリデーション
    const count = countCharacters(textAreaState?.expectation);
    if (count < 100 || count > 1000) {
      setExpectationErrorText(TEXTAREA_CHARACTER_LENGTH_VALIDATION_TEXT);
      return;
    }

    setExpectationErrorText(null);
  }, [textAreaState?.expectation]);

  // バリデーションエラー文言を反映する
  useEffect(() => {
    if (isClicked)
      setErrorTexts({
        // basicAndEmployment
        client: clientErrorText || "",
        employmentStatus: employmentStatusErrorText || "",
        continuedServiceYears: continuedServiceYearsErrorText || "",
        businessType: businessTypesErrorText || "",
        occupation: occupationErrorText || "",
        employment: employmentErrorText || "",
        // treatmentAndCulture
        treatments: treatmentsErrorText || "",
        cultures: culturesErrorText || "",
        // workplaceReviewFeature
        atmosphere: atmosphereErrorText || "",
        salaryStandard: salaryStandardErrorText || "",
        debut: debutErrorText || "",
        education: educationErrorText || "",
        workTime: workTimeErrorText || "",
        salaryChange: salaryChangeErrorText || "",
        // SalaryData
        monthlyIncome: monthlyIncomeErrorText || "",
        minimumGuaranteedIncome: minimumGuaranteedIncomeErrorText || "",
        grossMonthlyIncome: grossMonthlyIncomeErrorText || "",
        hourlyWage: hourlyWageErrorText || "",
        paymentYear: paymentYearErrorText || "",
        paymentCount: paymentCountErrorText || "",
        totalAmount: totalAmountErrorText || "",
        // workplaceReviewThought
        workplaceMatching: workplaceMatchingErrorText || "",
        interestReward: interestRewardErrorText || "",
        challenge: challengeErrorText || "",
        expectation: expectationErrorText || "",
      });
  }, [
    isClicked,
    // BasicAndEmployment
    clientErrorText,
    employmentStatusErrorText,
    continuedServiceYearsErrorText,
    businessTypesErrorText,
    occupationErrorText,
    employmentErrorText,
    // TreatmentAndCulture
    treatmentsErrorText,
    culturesErrorText,
    // WorkplaceReviewFeature
    atmosphereErrorText,
    salaryStandardErrorText,
    debutErrorText,
    educationErrorText,
    workTimeErrorText,
    salaryChangeErrorText,
    // SalaryData
    monthlyIncomeErrorText,
    minimumGuaranteedIncomeErrorText,
    grossMonthlyIncomeErrorText,
    hourlyWageErrorText,
    paymentYearErrorText,
    paymentCountErrorText,
    totalAmountErrorText,
    // WorkplaceReviewThought
    workplaceMatchingErrorText,
    interestRewardErrorText,
    challengeErrorText,
    expectationErrorText,
  ]);

  // バリデーションが全て通っている場合のみisValidをtrueにして送信できるようにする
  useEffect(() => {
    // バリデーションが通っていない場合はisValidをfalseにする
    if (
      // basicAndEmployment
      clientErrorText ||
      employmentStatusErrorText ||
      continuedServiceYearsErrorText ||
      businessTypesErrorText ||
      occupationErrorText ||
      employmentErrorText ||
      // treatmentAndCulture
      treatmentsErrorText ||
      culturesErrorText ||
      // workplaceReviewFeature
      atmosphereErrorText ||
      salaryStandardErrorText ||
      debutErrorText ||
      educationErrorText ||
      workTimeErrorText ||
      salaryChangeErrorText ||
      // salaryData
      monthlyIncomeErrorText ||
      minimumGuaranteedIncomeErrorText ||
      grossMonthlyIncomeErrorText ||
      hourlyWageErrorText ||
      paymentYearErrorText ||
      paymentCountErrorText ||
      totalAmountErrorText ||
      // workplaceReviewThought
      workplaceMatchingErrorText ||
      interestRewardErrorText ||
      challengeErrorText ||
      expectationErrorText
    ) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  }, [
    clientErrorText,
    continuedServiceYearsErrorText,
    atmosphereErrorText,
    challengeErrorText,
    checkboxValues,
    culturesErrorText,
    debutErrorText,
    educationErrorText,
    occupationErrorText,
    occupationValue,
    employmentErrorText,
    employmentStatusErrorText,
    employmentValue,
    expectationErrorText,
    interestRewardErrorText,
    businessTypeValue,
    businessTypesErrorText,
    radioValues,
    salaryChangeErrorText,
    salaryStandardErrorText,
    textAreaState,
    treatmentsErrorText,
    workTimeErrorText,
    workplaceMatchingErrorText,
    monthlyIncomeErrorText,
    minimumGuaranteedIncomeErrorText,
    grossMonthlyIncomeErrorText,
    hourlyWageErrorText,
    paymentYearErrorText,
    paymentCountErrorText,
    totalAmountErrorText,
  ]);
  return {
    isValid,
    errorTexts,
    updateClickStatus,
  };
};
