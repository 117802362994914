export const DUMMY_IMAGE_PATH =
  "data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7";
export const USER_REWARD_NAME = "勤続支援金";

// アプリ関連
// アプリWebView閲覧時にbodyのdata属性(data-mobile-app)に以下が入る
export const IOS_APP_ID = 1;
export const ANDROID_APP_ID = 2;

// modelのattribute名は１箇所で管理した方がいいかも
export const MODEL_ATTRIBUTE_NAME = {
  workContent: {
    occupation: "役職・働き方",
    occupationText: "役職・働き方の詳細",
  },
};

export const EMPLOYMENT_TYPE = {
  FULL_TIME_ID: 1, // 正社員
  OTHER_ID: 2, // その他
  CONTRACT_ID: 3, // 契約社員
  FREELANCE_ID: 4, // 業務委託
  PART_TIME_ID: 5, // アルバイト・パート
} as const;
