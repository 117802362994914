import { FC } from "react";
import { FormItemName } from "@root/shared/components/FormItemName";
import * as styles from "./styles.css";

type Props = {
  title: string;
  text: string;
  children: React.ReactNode;
};

export const FormField: FC<Props> = ({ title, text, children }) => (
  <div className={styles.wrapper}>
    <FormItemName>{title}</FormItemName>
    <p className={styles.text}>{text}</p>
    {children}
  </div>
);
