import { FC, useState, useEffect } from "react";
import { axiosPost } from "@root/utils/axiosForRails";
import { AxiosError } from "axios";
import { RestoreData } from "@userFrontend/features/clientReview/components/ClientReviewInputs/type";
import { Presentation } from "./Presentation";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

type Response = {
  status: number;
  data: RestoreData;
};

type Props = {
  isShowBackupModal: boolean;
  restoreBackupPath: string;
  deleteBackupPath: string;
  handleRestore: (data: RestoreData) => void;
};

export const RestoreClientReviewInputsModal: FC<Props> = ({
  isShowBackupModal,
  restoreBackupPath,
  deleteBackupPath,
  handleRestore,
}) => {
  const [isShow, setIsShow] = useState(isShowBackupModal);

  const restoreBackup = async () => {
    await axiosPost
      .post(restoreBackupPath)
      .then((response: Response) => {
        if (response.status === 200) {
          // レスポンスデータを親コンポーネントに渡す
          handleRestore(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (typeof newrelic !== "undefined") {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          newrelic.noticeError(error.message);
        }
      });
    setIsShow(false);
  };

  const deleteBackup = async () => {
    await axiosPost.post(deleteBackupPath).catch((error: AxiosError) => {
      if (typeof newrelic !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        newrelic.noticeError(error.message);
      }
    });
    setIsShow(false);
  };

  useEffect(() => {
    setIsShow(isShowBackupModal);
  }, [isShowBackupModal]);

  return (
    <Presentation
      isShow={isShow}
      closeModalHandler={() => setIsShow(false)}
      restoreBackupHandler={restoreBackup}
      deleteBackupHandler={deleteBackup}
    />
  );
};
