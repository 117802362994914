import { ComponentProps, useCallback } from "react";
import { OptionProps } from "@root/shared/components/SelectBox";
import { BasicAndEmployment } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/BasicAndEmployment";
import { TreatmentAndCulture } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/TreatmentAndCulture";
import { WorkplaceReviewFeature } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/WorkplaceReviewFeature";
import { WorkplaceReviewThought } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/WorkplaceReviewThought";
// utils
import { SiteId } from "@root/shared/types/type";
import { PlaceholderText } from "./utils/placeholderText";
// types
import {
  OccupationsProps,
  EmploymentsProps,
  BusinessTypesProps,
  SelectOptionQuestionsProps,
  TextAreaQuestionsProps,
} from "./type";

export const useFormatQuestion = (
  siteId: SiteId,
  occupations: OccupationsProps,
  employments: EmploymentsProps,
  businessTypes: BusinessTypesProps,
  selectiveQuestions: SelectOptionQuestionsProps,
  textAreaQuestions: TextAreaQuestionsProps,
): {
  basicAndEmploymentQuestions: ComponentProps<typeof BasicAndEmployment>["questions"];
  treatmentAndCultureQuestions: ComponentProps<typeof TreatmentAndCulture>["questions"];
  workplaceReviewFeatureQuestions: ComponentProps<typeof WorkplaceReviewFeature>["questions"];
  workplaceReviewThoughtQuestions: ComponentProps<typeof WorkplaceReviewThought>["questions"];
} => {
  const siteKey = siteId === 1 ? "biyo" : "kaigo";

  const formatQuestions = useCallback(() => {
    const businessTypesSelectOptions: OptionProps[] = businessTypes[siteKey].map(
      (businessType) => ({
        label: businessType.businessTypeName,
        value: businessType.businessTypeId,
      }),
    );

    const occupationsSelectOptions: OptionProps[] = occupations.map((occupation) => ({
      label: occupation.occupationName,
      value: occupation.occupationId,
      businessTypeIds: occupation.businessTypeIds,
    }));

    const employmentsSelectOptions: OptionProps[] = employments.map((employment) => ({
      label: employment.employmentName,
      value: employment.employmentId,
    }));

    const continuedServiceYearsSelectOptions: OptionProps[] =
      selectiveQuestions.continuedServiceYears[siteKey].map((continuedServiceYear) => ({
        label: continuedServiceYear.optionText,
        value: continuedServiceYear.optionId,
      }));

    const basicAndEmploymentQuestions = {
      occupationsSelectOptions,
      employmentsSelectOptions,
      employmentStatus: selectiveQuestions.employmentStatus[siteKey],
      continuedServiceYearsSelectOptions,
      businessTypesSelectOptions,
    };

    const treatmentAndCultureQuestions = {
      treatmentQuestions: selectiveQuestions.treatments[siteKey],
      cultureQuestions: selectiveQuestions.cultures[siteKey],
    };

    const workplaceReviewFeatureQuestions = {
      atmosphere: selectiveQuestions.atmosphere[siteKey],
      salaryStandard: selectiveQuestions.salaryStandard[siteKey],
      debut: selectiveQuestions.debut[siteKey],
      education: selectiveQuestions.education[siteKey],
      workTime: selectiveQuestions.workTime[siteKey],
      salaryChange: selectiveQuestions.salaryChange[siteKey],
    };

    const workplaceReviewThoughtQuestions = {
      workplaceMatching: {
        ...textAreaQuestions.workplaceMatching[siteKey][0],
        placeholder: PlaceholderText.workplaceMatching,
      },
      interestReward: {
        ...textAreaQuestions.interestReward[siteKey][0],
        placeholder: PlaceholderText.interestReward,
      },
      challenge: {
        ...textAreaQuestions.challenge[siteKey][0],
        placeholder: PlaceholderText.challenge,
      },
      expectation: {
        ...textAreaQuestions.expectation[siteKey][0],
        placeholder: PlaceholderText.expectation,
      },
    };

    return {
      basicAndEmploymentQuestions,
      treatmentAndCultureQuestions,
      workplaceReviewFeatureQuestions,
      workplaceReviewThoughtQuestions,
    };
  }, [siteKey, occupations, employments, businessTypes, selectiveQuestions, textAreaQuestions]);

  return formatQuestions();
};
