import { FC } from "react";
import { SelectBox } from "@root/shared/components/SelectBox";
import { TextField } from "@root/shared/components/TextField";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import * as styles from "./styles.css";

type Props = {
  bonusPaymentYearOptions: { label: string; value: number }[];
  bonusPaymentYearValue?: number | null;
  bonusPaymentCountValue?: number | null;
  bonusTotalAmountValue?: number | null;
  handleBonusPaymentYearSelect: React.FocusEventHandler<HTMLSelectElement>;
  handleBonusPaymentCountSelect: React.FocusEventHandler<HTMLSelectElement>;
  handleBonusTotalAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorBonusPaymentYearOptionsText?: ErrorText;
  errorBonusPaymentCountOptionsText?: ErrorText;
  errorBonusTotalAmountText?: ErrorText;
  isDisabled?: boolean;
};

export const BonusInputs: FC<Props> = ({
  bonusPaymentYearOptions,
  bonusPaymentYearValue,
  bonusPaymentCountValue,
  bonusTotalAmountValue,
  handleBonusPaymentYearSelect,
  handleBonusPaymentCountSelect,
  handleBonusTotalAmountChange,
  errorBonusPaymentYearOptionsText,
  errorBonusPaymentCountOptionsText,
  errorBonusTotalAmountText,
  isDisabled,
}) => {
  // NOTE: 回数の選択肢は固定で良いのでフロント側で持つ
  const countOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.paymentYearAndCountWrapper}>
        <p className={styles.label}>支給年度</p>
        <SelectBox
          name="paymentYear"
          placeholder="年度"
          options={bonusPaymentYearOptions}
          selected={!!bonusPaymentYearValue}
          onChange={handleBonusPaymentYearSelect}
          hasError={!!errorBonusPaymentYearOptionsText}
          disabled={isDisabled}
          value={bonusPaymentYearValue ?? ""}
        />
      </div>
      <div className={styles.paymentYearAndCountWrapper}>
        <p className={styles.label}>支給回数(年)</p>
        <SelectBox
          name="paymentCount"
          placeholder="回数"
          options={countOptions}
          selected={!!bonusPaymentCountValue}
          onChange={handleBonusPaymentCountSelect}
          hasError={!!errorBonusPaymentCountOptionsText}
          disabled={isDisabled}
          value={bonusPaymentCountValue ?? ""}
        />
      </div>
      <div className={styles.totalAmountWrapper}>
        <p className={styles.label}>支給総額(年)</p>
        <span className={styles.flexWrapper}>
          <TextField
            name="totalAmount"
            placeholder="100000"
            inputMode="numeric"
            value={bonusTotalAmountValue ?? ""}
            onChange={handleBonusTotalAmountChange}
            hasError={!!errorBonusTotalAmountText}
            disabled={isDisabled}
          />
          <span className={styles.unit}>円</span>
        </span>
      </div>
    </div>
  );
};
