import { mountComponents as mount } from "react-rails-ujs";

import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { Icon } from "@root/shared/components/Icon";
import { StickyAnchorLinks } from "@root/shared/components/StickyAnchorLinks";
import { AnchorLinks } from "@root/shared/components/AnchorLinks";
import { HeadingAndContents } from "@userFrontend/features/publication/components/HeadingAndContents";
import { CategoryTopCassettes } from "@userFrontend/features/publication/components/CategoryTopCassettes";
import { RecommendWorks } from "@userFrontend/features/publication/components/RecommendWorks";
import { PieChart } from "@userFrontend/features/publication/components/PieChart";
import { RatioChart } from "@userFrontend/features/publication/components/RatioChart";
import { TopCarousel } from "@userFrontend/features/publication/components/TopCarousel";
import { RatioWithDotChart } from "@userFrontend/features/publication/components/RatioWithDotChart";
import { SelectLinkButton } from "@userFrontend/features/publication/components/SelectLinkButton";
import { SpLineBannerModal } from "@userFrontend/features/publication/components/SpLineBannerModal";
import { JobEntryRadioButtonsWithModal } from "@userFrontend/features/reward/components/JobEntryRadioButtonsWithModal";
import { TextAreaWithWordCounter } from "@userFrontend/components/TextAreaWithWordCounter";
import { UserRewardStepIndicator } from "@userFrontend/features/reward/components/UserRewardStepIndicator";
import { AboutWorkplaceInputs } from "@userFrontend/features/reward/components/AboutWorkplaceInputs";
import { AboutRejobServiceInputs } from "@userFrontend/features/reward/components/AboutRejobServiceInputs";
import { RestoreUserRewardInputsModal } from "@userFrontend/features/reward/components/RestoreUserRewardInputsModal";
import { RestoreUserRewardContinuedInputsModal } from "@userFrontend/features/reward/components/RestoreUserRewardContinuedInputsModal";
import { WebToAppBanner } from "@userFrontend/components/WebToAppBanner";
import { SmartAppBanner } from "@userFrontend/components/SmartAppBanner";
import { LineAppModal } from "@userFrontend/features/publication/components/LineAppModal";
import { SkillAccordionList } from "@userFrontend/features/publication/components/SkillAccordionList";
import { TelInformationFloating } from "@userFrontend/features/publication/components/TelInformationFloating";
import { MatchingSupportInfoModal } from "@userFrontend/features/entry/components/MatchingSupportInfoModal";
import { ContinuedRewardInputs } from "@userFrontend/features/reward/components/ContinuedRewardInputs";
import { ClientReviewPopover } from "@userFrontend/features/reward/components/ClientReviewPopover";
import { ClientReviewInputs } from "@userFrontend//features/clientReview/components/ClientReviewInputs";
import { ClientReviewCassettes } from "@userFrontend//features/clientReview/components/ClientReviewCassettes";
import { ClientReviewDescription } from "@userFrontend//features/clientReview/components/ClientReviewDescription";
import { WorkDetailBottomButtons } from "@userFrontend/features/publication/components/WorkDetailBottomButtons";
import { SearchResultExplainModal } from "@userFrontend/features/publication/components/SearchResultExplainModal";
import { UserExperienceVoice } from "@userFrontend/features/publication/components/UserExperienceVoice";
import { TableOfContents } from "@userFrontend/features/clientReview/components/TableOfContents";
import { AttentionOnUnsolicitedEmail } from "@userFrontend/features/publication/components/AttentionOnUnsolicitedEmail";

// [要リファクタ]以下、mountComponentsを１つにする為、legacyからimportしている
// pages
import { SearchSalaryHistogram } from "@legacy_user_frontend/components/pages/jobs/job_salary_histogram";
import { JobAverageGraph } from "@legacy_user_frontend/components/pages/jobs/job_average_graph";
import { JobSearchNavigation } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation";
import { ClientOwnerContents } from "@legacy_user_frontend/components/pages/client/client_owner_contents";
import { ClientBrandContents } from "@legacy_user_frontend/components/pages/client/client_brand_contents";
import { RegistrationCompleteAppBanner } from "@legacy_user_frontend/components/pages/mypage/registrations/registration_complete_app_banner";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";
import { ProfilesShow } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_show";
import { ProfilesEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit";
import { SchoolLogsEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/school_logs_edit";
import { CredentialsEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit";
import { AvailableStartDateEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/available_start_date_edit";
import { ContactableTimeRangeEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/contactable_time_range_edit";
import { SelfPrEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/self_pr_edit";
import { ProductPhotoEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/product_photo_edit";
import { UserCareerEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/user_career_edit";
import { SkillEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/skills_edit";
import { AchievementEdit } from "@legacy_user_frontend/components/pages/mypage/profiles/achievement_edit";

// projects
import { Cassettes } from "@legacy_user_frontend/components/projects/cassettes";
import { ButtonContents } from "@legacy_user_frontend/components/projects/button_contents";

// ui_parts
import { KeepButton } from "@legacy_user_frontend/components/ui_parts/buttons/keep_button";

// dev環境のみモックサーバーを立ち上げる
import { startMockServerIfDevelopment } from "@root/mocks/startMockServerIfDevelopment";

startMockServerIfDevelopment();

export const mountComponents = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  mount({
    Icon,
    HeadingAndContents,
    CategoryTopCassettes,
    RecommendWorks,
    RatioChart,
    PieChart,
    TopCarousel,
    SelectLinkButton,
    SpLineBannerModal,
    JobEntryRadioButtonsWithModal,
    TextAreaWithWordCounter,
    UserRewardStepIndicator,
    AboutWorkplaceInputs,
    AboutRejobServiceInputs,
    RestoreUserRewardInputsModal,
    RestoreUserRewardContinuedInputsModal,
    Cassettes,
    TableOfContents,
    AttentionOnUnsolicitedEmail,
    SearchSalaryHistogram,
    JobAverageGraph,
    JobSearchNavigation,
    ClientOwnerContents,
    ClientBrandContents,
    RegistrationCompleteAppBanner,
    WebToAppBanner,
    SmartAppBanner,
    LineAppModal,
    SkillAccordionList,
    StickyAnchorLinks,
    AnchorLinks,
    LazyLoadImage,
    ButtonContents,
    KeepButton,
    PrivateLabel,
    ProfilesShow,
    ProfilesEdit,
    SchoolLogsEdit,
    CredentialsEdit,
    AvailableStartDateEdit,
    ContactableTimeRangeEdit,
    SelfPrEdit,
    ProductPhotoEdit,
    UserCareerEdit,
    SkillEdit,
    AchievementEdit,
    RatioWithDotChart,
    TelInformationFloating,
    ContinuedRewardInputs,
    ClientReviewPopover,
    ClientReviewInputs,
    ClientReviewCassettes,
    ClientReviewDescription,
    MatchingSupportInfoModal,
    WorkDetailBottomButtons,
    SearchResultExplainModal,
    UserExperienceVoice,
  });
};

mountComponents();
