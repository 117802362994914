import useSWR, { SWRConfiguration } from "swr";

const IMMUTABLE_MODE_OPTION = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

type Props = {
  url: string | null;
  params?: Record<string, string | string[]>;
  options?: SWRConfiguration;
};

export const useFetchData = <T>({ url, params, options }: Props) => {
  const fetcher = (fetcherUrl: string): Promise<T> =>
    fetch(fetcherUrl, params).then(async (res) => {
      const data = await res.json();
      if (!res.ok) {
        throw new Error();
      }
      return data as Promise<T>;
    });

  return useSWR<T, Error>(url, fetcher, {
    ...IMMUTABLE_MODE_OPTION,
    ...options,
  });
};
