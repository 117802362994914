import { FC } from "react";
import * as styles from "./styles.css";

type Props = {
  salaryArray: string[];
  bonusArray: string[];
};

export const ConfirmSalariesDataContents: FC<Props> = ({ salaryArray, bonusArray }) => {
  return (
    <dl className={styles.list}>
      <dt className={styles.listTitle}>給与</dt>
      <dd>
        {salaryArray.map((salary) => (
          <p key={salary}>{salary}</p>
        ))}
      </dd>
      <dt className={styles.listTitle}>賞与・ボーナス</dt>
      <dd>
        {bonusArray.map((bonus) => (
          <p key={bonus}>{bonus}</p>
        ))}
      </dd>
    </dl>
  );
};
