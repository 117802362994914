import { FC, ReactNode } from "react";
import { Label } from "@root/shared/components/Label";
import * as styles from "./styles.css";

type Props = {
  children: ReactNode;
  label?: ReactNode;
  labelRequirementType?: "required" | "optional";
};

export const FormItemName: FC<Props> = ({ children, label, labelRequirementType }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        {children}
        {labelRequirementType === "required" && (
          <Label theme="red" size="small" className={styles.label}>
            <span>必須</span>
          </Label>
        )}
        {labelRequirementType === "optional" && (
          <Label theme="gray" size="small" className={styles.label}>
            <span>任意</span>
          </Label>
        )}
      </div>
      {label}
    </div>
  );
};
