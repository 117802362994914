import { ComponentProps, FC, useEffect, useState } from "react";
import { useHistoryBack } from "@userFrontend/hooks/useHistoryBack";
import { Presentation } from "./Presentation";
import { JobEntryRadioButton } from "./uiParts/JobEntryRadioButton";

type Props = {
  initialCheckedJobEntryId: number | null;
  jobEntryButtons: Pick<
    ComponentProps<typeof JobEntryRadioButton>,
    "clientName" | "brandName" | "workTitle" | "jobEntryId" | "disabled"
  >[];
  userContactUrl: string;
  jobEntryIdsWithFalsityPotential: number[];
  isSp: boolean;
  isError: boolean;
};

export const JobEntryRadioButtonsWithModal: FC<Props> = ({
  initialCheckedJobEntryId,
  jobEntryButtons,
  userContactUrl,
  jobEntryIdsWithFalsityPotential,
  isSp,
  isError,
}) => {
  const [checkedJobEntryId, setCheckedJobEntryId] = useState<number | null>(
    initialCheckedJobEntryId,
  );
  const [showModal, setShowModal] = useState(false);
  const checkedJobEntryWithFalsityPotential =
    !!checkedJobEntryId && jobEntryIdsWithFalsityPotential.includes(checkedJobEntryId);
  const isExit = useHistoryBack(!checkedJobEntryWithFalsityPotential);

  useEffect(() => {
    if (!isExit) {
      return;
    }

    setShowModal(true);
  }, [isExit]);

  useEffect(() => {
    const statePresent = window && window.history.state && window.history.state.isExit === false;
    const historyBack = () => {
      window.history.go(-1);
    };
    if (statePresent && checkedJobEntryWithFalsityPotential) {
      window.removeEventListener("popstate", historyBack);
    } else {
      window.addEventListener("popstate", historyBack);
    }
    return () => {
      window.removeEventListener("popstate", historyBack);
    };
  }, [isExit, checkedJobEntryWithFalsityPotential]);

  const changeJobEntryHandler = (jobEntryId: number) => setCheckedJobEntryId(jobEntryId);

  const modalCloseHandler = () => {
    setShowModal(false);
    window.history.back();
  };

  const modalProps = {
    showModal,
    closeHandler: modalCloseHandler,
    userContactUrl,
  };

  const radioButtonsProps = {
    jobEntryButtons,
    checkedJobEntryId,
    isSp,
    isError,
    userContactUrl,
    changeJobEntryHandler,
  };

  return <Presentation {...modalProps} {...radioButtonsProps} />;
};
