import { FC, useMemo, Suspense, lazy } from "react";
import { Settings } from "react-slick";
import { USER_REWARD_NAME } from "@root/utils/constants";
import { Presentation, Props as PresentationProps } from "./Presentation";
import * as styles from "./styles.css";

const Slider = lazy(() => import("react-slick"));

const setting: Settings = {
  lazyLoad: "ondemand",
  infinite: true,
  autoplay: true,
  pauseOnHover: true,
  slidesToScroll: 1,
  speed: 300,
  autoplaySpeed: 5000,
  touchThreshold: 10,
  dots: true,
  arrows: false,
  dotsClass: styles.pagenationList,
  customPaging: () => <span />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        autoplaySpeed: 6000,
      },
    },
  ],
};

type Props = {
  isShowRegistration: boolean;
  isShowAppPromotion: boolean;
  registrationPath: string;
  appPromotionPath: string;
  oiwaiPath: string;
  imageRootUrl: string;
  isUserRewardOutOfService: boolean;
};

export const TopCarousel: FC<Props> = ({
  isShowRegistration,
  isShowAppPromotion,
  registrationPath,
  appPromotionPath,
  oiwaiPath,
  imageRootUrl,
  isUserRewardOutOfService,
}) => {
  const viewData = useMemo(
    (): (PresentationProps & { isShow: boolean })[] => [
      {
        isShow: true,
        type: "billboard",
        pcImage: "/images/user_frontend/pc/img_top_keyv_billboard.jpg",
        spImage: "/images/user_frontend/sp/img_top_keyv_billboard.jpg",
        alt: "私の手にしか、できないシゴト。 美容・ヘルスケアの求人&転職サイト 転職満足度98%",
        isSmall: true,
      },
      {
        isShow: isShowRegistration,
        type: "registration",
        path: registrationPath,
        gaParam: {
          action: "registration_promotion",
          label: "grand_top_banner",
        },
        pcImage: "/images/user_frontend/global/img_top_keyv_scout_pc.png",
        spImage: "/images/user_frontend/global/img_top_keyv_scout_sp.png",
        alt: "スカウトから始まる、就職/転職 スカウトを受け取るには",
      },
      {
        isShow: isShowAppPromotion,
        type: "appPromotion",
        path: appPromotionPath,
        gaParam: {
          action: "app_promotion",
          label: "grand_top_banner",
        },
        pcImage: "/images/user_frontend/pc/app_promotion_grand_top_banner_pc.jpg",
        spImage: "/images/user_frontend/sp/app_promotion_grand_top_banner_sp.jpg",
        alt: "企業があなたを見てくれる　リジョブの転職アプリ",
      },
    ],
    [isShowRegistration, registrationPath, isShowAppPromotion, appPromotionPath],
  );

  const oiwaiImageData: PresentationProps = useMemo(
    () => ({
      isShow: true,
      type: "oiwai",
      path: oiwaiPath,
      gaParam: {
        action: "Top_banner_oiwaikin",
      },
      pcImage: "/images/user_frontend/pc/img_top_keyv_oiwai.png",
      spImage: "/images/user_frontend/sp/img_top_keyv_oiwai.png",
      alt: `対象者全員 2回もらえる${USER_REWARD_NAME} 入社後30日最大10,000円＋入社後1年最大10,000円`,
    }),
    [oiwaiPath],
  );

  return (
    <Suspense fallback={<div className="c-loading" />}>
      <Slider {...setting}>
        {viewData.map(
          (data, index) =>
            data.isShow && (
              <Presentation
                key={data.type}
                type={data.type}
                path={data.path}
                gaParam={data.gaParam}
                pcImage={`${imageRootUrl}${data.pcImage}`}
                spImage={`${imageRootUrl}${data.spImage}`}
                alt={data.alt}
                isSmall={data.isSmall}
                lazyLoad={index !== 0}
              />
            ),
        )}
        {!isUserRewardOutOfService && (
          <Presentation
            type={oiwaiImageData.type}
            pcImage={oiwaiImageData.pcImage}
            spImage={oiwaiImageData.spImage}
            alt={oiwaiImageData.alt}
          />
        )}
      </Slider>
    </Suspense>
  );
};
