import { FC, RefObject, ComponentProps } from "react";
import { SectionTitle } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SectionTitle";
import { commonInner } from "@userFrontend/features/clientReview/components/ClientReviewInputs/styles.css";
import { GuidanceBox } from "@root/shared/components/GuidanceBox";
import { Label } from "@root/shared/components/Label";
import { Heading } from "@userFrontend/components/Heading";
import { BonusInputs } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/BonusInputs";
import { FormField } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/FormField";
import { TextField } from "@root/shared/components/TextField";
import { Checkbox } from "@root/shared/components/Checkbox";
import { ErrorText } from "@userFrontend/features/reward/components/ContinuedRewardInputs/type";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { EMPLOYMENT_TYPE } from "@root/utils/constants";
import * as styles from "./styles.css";

type Props = {
  monthlyIncomeRef: RefObject<HTMLDivElement>;
  minimumGuaranteedIncomeRef: RefObject<HTMLDivElement>;
  grossMonthlyIncomeRef: RefObject<HTMLDivElement>;
  hourlyWageRef: RefObject<HTMLDivElement>;
  bonusRef: RefObject<HTMLDivElement>;
  monthlyIncomeValue?: number | null;
  minimumGuaranteedIncomeValue?: number | null;
  grossMonthlyIncomeValue?: number | null;
  hourlyWageValue?: number | null;
  monthlyIncomeErrorText?: ErrorText;
  minimumGuaranteedIncomeErrorText?: ErrorText;
  grossMonthlyIncomeErrorText?: ErrorText;
  hourlyWageErrorText?: ErrorText;
  handleMonthlyIncomeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMinimumGuaranteedIncomeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleGrossMonthlyIncomeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleHourlyWageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMinimumGuaranteedIncomeCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBonusCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleHiddenFlagCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  noGuaranteedIncomeFlag?: boolean;
  noBonusFlag?: boolean;
  hiddenFlag?: boolean;
  employmentValue: number | undefined;
  bonusInputsProps: Omit<ComponentProps<typeof BonusInputs>, "isDisabled">;
};

export const SalaryData: FC<Props> = ({
  monthlyIncomeRef,
  minimumGuaranteedIncomeRef,
  grossMonthlyIncomeRef,
  hourlyWageRef,
  bonusRef,
  monthlyIncomeValue,
  minimumGuaranteedIncomeValue,
  grossMonthlyIncomeValue,
  hourlyWageValue,
  monthlyIncomeErrorText,
  minimumGuaranteedIncomeErrorText,
  grossMonthlyIncomeErrorText,
  hourlyWageErrorText,
  handleMonthlyIncomeChange,
  handleMinimumGuaranteedIncomeChange,
  handleGrossMonthlyIncomeChange,
  handleHourlyWageChange,
  handleMinimumGuaranteedIncomeCheckboxChange,
  handleBonusCheckboxChange,
  handleHiddenFlagCheckboxChange,
  noGuaranteedIncomeFlag,
  noBonusFlag,
  hiddenFlag,
  employmentValue,
  bonusInputsProps,
}) => {
  return (
    <>
      <SectionTitle text="給与データ" />
      <div className={commonInner}>
        <GuidanceBox backgroundColor="pink">
          <ul className={styles.list}>
            <li>ご記入いただきたい内容</li>
            {employmentValue !== EMPLOYMENT_TYPE.PART_TIME_ID && (
              <li className={styles.dotListItem}>手取額ではなく額面の金額</li>
            )}
            <li className={styles.dotListItem}>現職の方は現時点の金額</li>
            <li className={styles.dotListItem}>退職済みの方は退職時の金額</li>
          </ul>
        </GuidanceBox>
        <div className={styles.salaryContainer}>
          <Heading
            className={styles.heading}
            tag="h2"
            size="small"
            label={<Label theme="red">必須</Label>}
          >
            給与
          </Heading>
          {(employmentValue === EMPLOYMENT_TYPE.FULL_TIME_ID ||
            employmentValue === EMPLOYMENT_TYPE.CONTRACT_ID) && (
            <div className={styles.salaryItemOuter} ref={monthlyIncomeRef}>
              <FormField title="基本給(月)" text="毎月固定で支給される金額">
                <div className={styles.salaryItemInner}>
                  <TextField
                    name="monthlyIncome"
                    value={monthlyIncomeValue ?? ""}
                    onChange={handleMonthlyIncomeChange}
                    hasError={!!monthlyIncomeErrorText}
                    inputMode="numeric"
                    placeholder="300000"
                  />
                  円
                </div>
                {monthlyIncomeErrorText && <ErrorInformationText text={monthlyIncomeErrorText} />}
              </FormField>
            </div>
          )}
          {employmentValue === EMPLOYMENT_TYPE.FREELANCE_ID && (
            <div className={styles.salaryItemOuter} ref={minimumGuaranteedIncomeRef}>
              <FormField title="最低保証給(月)" text="毎月固定で支給される金額">
                <div className={styles.checkboxTextOuter}>
                  <Checkbox
                    id="checkbox-minimumGuaranteedIncome"
                    checked={noGuaranteedIncomeFlag}
                    onChange={handleMinimumGuaranteedIncomeCheckboxChange}
                    hasError={!!minimumGuaranteedIncomeErrorText}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="checkbox-minimumGuaranteedIncome">
                    最低保証給はない（完全歩合）
                  </label>
                </div>
                <div className={styles.salaryItemInner}>
                  <TextField
                    name="minimumGuaranteedIncome"
                    value={minimumGuaranteedIncomeValue ?? ""}
                    onChange={handleMinimumGuaranteedIncomeChange}
                    hasError={!!minimumGuaranteedIncomeErrorText}
                    inputMode="numeric"
                    placeholder="300000"
                    disabled={noGuaranteedIncomeFlag}
                  />
                  円
                </div>
                {minimumGuaranteedIncomeErrorText && (
                  <ErrorInformationText text={minimumGuaranteedIncomeErrorText} />
                )}
              </FormField>
            </div>
          )}
          {(employmentValue === EMPLOYMENT_TYPE.FULL_TIME_ID ||
            employmentValue === EMPLOYMENT_TYPE.CONTRACT_ID ||
            employmentValue === EMPLOYMENT_TYPE.FREELANCE_ID) && (
            <div className={styles.salaryItemOuter} ref={grossMonthlyIncomeRef}>
              <FormField
                title="総支給(月)"
                text="基本給 + 月によって変動する手当（インセンティブなど）"
              >
                <div className={styles.salaryItemInner}>
                  <TextField
                    name="grossMonthlyIncome"
                    value={grossMonthlyIncomeValue ?? ""}
                    onChange={handleGrossMonthlyIncomeChange}
                    hasError={!!grossMonthlyIncomeErrorText}
                    inputMode="numeric"
                    placeholder="400000"
                  />
                  円
                </div>
                {grossMonthlyIncomeErrorText && (
                  <ErrorInformationText text={grossMonthlyIncomeErrorText} />
                )}
              </FormField>
            </div>
          )}
          {employmentValue === EMPLOYMENT_TYPE.PART_TIME_ID && (
            <div className={styles.salaryItemOuter} ref={hourlyWageRef}>
              <div className={styles.salaryItemInner}>
                <span className={styles.hourlyWageText}>時給</span>
                <TextField
                  name="hourlyWage"
                  value={hourlyWageValue ?? ""}
                  onChange={handleHourlyWageChange}
                  hasError={!!hourlyWageErrorText}
                  inputMode="numeric"
                  placeholder="1500"
                />
                円
              </div>
              {hourlyWageErrorText && <ErrorInformationText text={hourlyWageErrorText} />}
            </div>
          )}
        </div>
        <div className={styles.bonusContainer}>
          <Heading
            className={styles.heading}
            tag="h2"
            size="small"
            label={<Label theme="red">必須</Label>}
          >
            賞与・ボーナス
          </Heading>
          <div className={styles.bonusItemOuter} ref={bonusRef}>
            <p className={styles.bonusItemText}>夏や冬など決まった時期に支給されるもの</p>
            <div className={styles.checkboxTextOuter}>
              <Checkbox
                id="checkbox-bonus"
                checked={noBonusFlag}
                onChange={handleBonusCheckboxChange}
                hasError={
                  !!bonusInputsProps.errorBonusPaymentYearOptionsText &&
                  !!bonusInputsProps.errorBonusPaymentCountOptionsText &&
                  !!bonusInputsProps.errorBonusTotalAmountText
                }
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="checkbox-bonus">支給なし・今後支給予定</label>
            </div>
            <BonusInputs {...bonusInputsProps} isDisabled={noBonusFlag} />
            {bonusInputsProps.errorBonusPaymentYearOptionsText && (
              <ErrorInformationText text={bonusInputsProps.errorBonusPaymentYearOptionsText} />
            )}
            {bonusInputsProps.errorBonusPaymentCountOptionsText && (
              <ErrorInformationText text={bonusInputsProps.errorBonusPaymentCountOptionsText} />
            )}
            {bonusInputsProps.errorBonusTotalAmountText && (
              <ErrorInformationText text={bonusInputsProps.errorBonusTotalAmountText} />
            )}
          </div>
        </div>
        <div className={styles.hiddenFlagOuter}>
          <Checkbox
            name="hiddenFlag"
            id="checkbox-hiddenFlag"
            checked={hiddenFlag}
            onChange={handleHiddenFlagCheckboxChange}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="checkbox-hiddenFlag">
            給与/賞与・ボーナスを個人単位で掲載しない
            <br />
            （※平均などの集計には利用されます。）
          </label>
        </div>
      </div>
    </>
  );
};
